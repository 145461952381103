<template>
  <el-container class="animate-nav">
    <el-header>
      <headerBox />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
    <el-footer
      :style="{
        'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px'
      }"
    >
      <div class="main-footer footer-stick container customize-width pb-3 px-md-5 mb-md-4">
        <div class="d-none d-md-block">
          <div class="card mt-4">
            <div class="card-body text-center font-weight-bold">
              想要以后继续访问本站，按住<span style="margin-left: 15px" class="keyboard-btn">Ctrl</span><span style="padding: 0 5px">+</span
              ><span style="margin-right: 15px" class="keyboard-btn">D</span>键，把<span style="color: var(--theme-color); margin: 0 5px"
                >huamao.vip</span
              >收藏起来吧！
            </div>
          </div>
        </div>
        <div class="text-center text-lg-left text-sm pt-4">
          <div class="row mb-3">
            <div class="col-12">
              <p class="footer-links">
                <a v-for="item of dbList" :key="item.id">{{ item.name }}</a>
              </p>
            </div>
          </div>
          <div class="row text-muted">
            <div class="col-12 col-lg-8 mb-3 mb-lg-0">
              <div class="mb-2">{{ info }}</div>
              <div class="footer-copyright">
                {{ beian }}&nbsp;&nbsp;<a href="137.html" target="_blank"
                  ><img
                    style="vertical-align: text-bottom"
                    src="https://img0.huamaocdn.com/upload/daohang/static/favicon-20x20.png"
                    width="18"
                    height="18"
                    :alt="wzTitle"
                  />&nbsp;&nbsp;{{ wzTitle }}</a
                >&nbsp;
              </div>
            </div>
            <div class="col-12 col-lg-4 text-lg-right">
              <div class="footer-mini-img">
                <p class="bg-light rounded-lg p-1">
                  <img class="lazy" :src="qq_qr.image" :data-src="qq_qr.image" :alt="qq_qr.title" />
                </p>

                <div class="mt-2 mb-3">{{ qq_qr.title }}</div>
              </div>
              <div class="footer-mini-img">
                <p class="bg-light rounded-lg p-1">
                  <img class="lazy" :src="vx_qr.image" :data-src="vx_qr.image" :alt="vx_qr.title" />
                </p>

                <div class="mt-2 mb-3">{{ vx_qr.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-footer>
    <!-- 点击回到顶部 -->
    <div class="back-to-top">
      <div class="li" v-show="visible" @click="backToTop">
        <i class="el-icon-arrow-up"></i>
      </div>
      <div class="li" @click="clickJump('/about')">
        <el-tooltip class="item" effect="dark" content="联系站长" placement="left">
          <i class="el-icon-chat-dot-round"></i>
        </el-tooltip>
      </div>
    </div>
  </el-container>
</template>

<script>
import headerBox from './components/header.vue'
import asideBar from '@/views/layout/components/aside'
import { GetArticle, getCat, getNav, guanggao, getNewsRand, setBrowse, GetArticleContent, gethead } from '@/api/http'
import { API_URL } from '@/utils/request.js'
// import { navBarListIndex, navBarListAbout, gobackList } from './components/data.js'
export default {
  components: { headerBox, asideBar },
  data() {
    return {
      isCollapse: this.$store.state.collapse,
      drawerDialog: false,
      navBarList: [],
      visible: false,
      info: '',
      beian: '',
      vx_qr: {
        image: '',
        title: ''
      },
      qq_qr: {
        image: '',
        title: ''
      },
      gywo: [],
      dbList: [],
      wzTitle: ''
    }
  },
  watch: {
    '$store.state.isMobile'(val) {
      if (!val) {
        this.$store.commit('toggleDrawer', false)
      }
    }
  },
  created() {
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
    window.addEventListener('scroll', this.handleScroll)
    this.GetArticleContent()
    this.getZiXun()
    this.getCatData()
    this.getBaijin()
  },
  mounted() {},
  destroyed() {
    window.removeEventListener('resize', this.checkMobile)
    window.removeEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    localStorage.clear()
  },
  methods: {
    //获取资讯
    async getZiXun() {
      const zixun = await getNewsRand()
      this.ziXunList = zixun.data.data
    },
    //获取首页getnav
    async getCatData() {
      const getCatData = await getCat()
      var getdata = getCatData.data.data
      getdata.forEach((item) => {
        item.image = API_URL + item.image
      })
      this.navBarList = getdata
    },
    async getBaijin() {
      var data1 = {
        type: 0
      }
      const baijin = await guanggao(data1)
      var guanggap = baijin.data.data
      guanggap.forEach((item) => {
        item.image = API_URL + item.image
      })
      this.guanggaoList = guanggap
    },
    //  底部导航
    async GetArticleContent() {
      const data = await GetArticle()
      var res = data.data.data
      const keyTitle = localStorage.getItem('keyTitle') ? localStorage.getItem('keyTitle') : 'Android'
      let home_title = ''
      let home_content = ''
      for (const key in res) {
        if (res.hasOwnProperty(key) && typeof res[key] === 'object') {
          //底部导航的邮箱
          if (res[key].name === 'beian') {
            this.beian = res[key].value
          }
          //底部导航的邮箱
          if (res[key].name === 'name') {
            this.wzTitle = res[key].value
            window.document.title = res[key].value
            localStorage.setItem('siteName', res[key].value)
          }
          if (['Android', 'Ios'].includes(key)) {
            res[key].forEach((li) => {
              //底部导航的描述文字
              if (li.name === keyTitle + '_info') {
                this.info = li.value
              }
              //qq二维码
              if (li.name == keyTitle + '_qq_qr') {
                this.qq_qr.image = API_URL + li.value
                this.qq_qr.title = li.name
              }

              //微信二维码
              if (li.name == keyTitle + '_vx_qr') {
                this.vx_qr.image = API_URL + li.value
                this.vx_qr.title = li.name
              }
              //标题
              if (li.name == keyTitle + '_title') {
                home_title = li.value
              }
              //内容
              if (li.name == keyTitle + '_content') {
                home_content = li.value
              }
            })
          }
        }
      }
      let dataHome = [home_title, home_content]
      localStorage.setItem('homeFrame', JSON.stringify(dataHome))
      var query1 = {
        type: 1
      }
      const data1 = await gethead(query1)
      // console.log('-----底部的关于我们那一行', JSON.stringify(data1.data.data))
      this.dbList = data1.data.data
    },
    // 判断是否是手机端
    checkMobile() {
      const flag = window.innerWidth <= 768
      this.$store.commit('isMobile', flag)
    },
    drawerShow() {
      this.drawerDialog = true
      this.isCollapse = true
    },
    // 锚点导航
    scrollToAnchor(refName) {
      if (refName == 'goback') {
        this.$router.go(-1)
      } else {
        this.$nextTick(() => {
          const element = document.querySelector('#' + refName)
          if (element) {
            // 平滑滚动到元素
            window.scrollTo({
              behavior: 'smooth',
              top: element.offsetTop - 80
            })
            this.$store.commit('isMobile', false)
          }
        })
      }
    },
    handleClose() {
      this.drawerDialog = false
    },

    toggleMenu() {
      this.isCollapse = !this.isCollapse
    },
    // 点击回到顶部
    handleScroll() {
      // 兼容不同浏览器的滚动位置获取方式
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      this.visible = scrollTop > 300 // 当页面滚动超过300px时显示按钮
    },
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    clickJump() {
      this.$router.push('/about')
    }
  }
}
</script>
<style scoped>
@import url('../css/bootstrap.css');
@import url('../css/style.css');
.el-main {
  padding: 0;
}
.lazy {
  width: 80px;
  height: 80px;
}
</style>
<style>
ul {
  padding: 0;
  margin: 0;
}
.container {
  .el-main {
    padding: 0;
  }
  .el-aside {
    position: fixed;
    display: table-cell;
    font-size: 0.875rem;
    height: 100vh;
    z-index: 1081;
    opacity: 1;
    .fade {
      opacity: 1;
    }
  }
}
.container.el-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  .el-footer {
    height: auto !important;
  }
}
.container.sidebar {
  .el-main,
  .el-footer,
  .navbar-collapse {
    margin-left: 0;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.li {
  color: #555;
  background: rgba(210, 210, 210, 0.5);
  width: 40px;
  height: 40px;
  font-size: 1rem;
  text-align: center;
  line-height: 40px;
  padding: unset;
  display: block;
  border: unset;
  border-radius: 50%;
  margin: 0.5rem 0.25rem;
  cursor: pointer;
  i {
    color: #333;
    font-weight: 600;
  }
}
</style>

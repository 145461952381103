<template>
  <div>
    <div id="header" class="page-header">
      <div class="sidebar-logo">
        <div class="logo">
          <div class="d-none d-md-block">
            <a v-if="isCollapse" class="logo-expanded">
              <img src="@/assets/logo-collapsed.png" @click="clickJump('/')" class="logo-light" :alt="siteName" />
              <img src="@/assets/logo-dark.png" @click="clickJump('/')" class="logo-dark d-none" :alt="siteName" />
              <span class="siteName">{{ siteName }}</span>
            </a>
            <a v-else><img src="@/assets/logo-collapsed.png" @click="clickJump('/')" :alt="siteName"/></a>
          </div>
        </div>
      </div>
      <div class="navbar navbar-expand-md">
        <div class="container-fluid p-0 position-relative">
          <a class="navbar-brand d-md-none">
            <img src="@/assets/logo-collapsed.png" class="logo-light" :alt="siteName" @click="clickJump('/')" />
            <img src="@/assets/logo-collapsed.png" class="logo-dark d-none" :alt="siteName" @click="clickJump('/')" />
            <span class="siteName">{{ siteName }}</span>
          </a>
          <div class="collapse navbar-collapse order-2 order-md-1" :style="{ 'margin-left': isCollapse ? '200px' : '60px' }">
            <div class="header-mini-btn">
              <div class="menu-div">
                <div class="menu-icon" @click="toggleMenu">
                  <div class="barDiv top" :class="{ active: isCollapse }"></div>
                  <div class="barDiv middle"></div>
                  <div class="barDiv bottom" :class="{ active: isCollapse }"></div>
                </div>
              </div>
            </div>
            <ul class="navbar-nav navbar-top mx-2">
              <li>
                <a @click="clickJump('/class')"><i class="io io-dengji icon-fw icon-lg"></i><span>所有类别</span></a>
              </li>
              <li>
                <a @click="clickJump('/new')"><i class="io io-gonggao icon-fw icon-lg"></i><span>最新收录</span></a>
              </li>
              <li>
                <a @click="clickJump('/ranking')"><i class="io io-huore icon-fw icon-lg"></i><span>排行榜</span></a>
              </li>
              <!-- <li>
              <a href="https://huamaobizhi.com/pictures/?orientation=2" target="_blank" rel="nofollow"
                ><i class="io io-xiangce icon-fw icon-lg"></i><span>电脑壁纸</span></a
              >
            </li> -->
              <li v-for="item of navigationList" :key="item.id">
                <a @click="clickJump2(item.link)">
                  <img :src="APIUM + item.image" style="width: 30px;height: 30px; margin-top: -2px;margin-right: 8px;" />
                  <span>{{ item.name }}</span></a
                >
              </li>
            </ul>
          </div>
          <ul class="nav navbar-menu text-xs order-1 order-md-2 position-relative" @click="drawerShow">
			  <li class="d-md-flex ml-3 ml-md-4" style="position: relative;right: 20px;">
			    <div class="btn-anime1">
			      <a href="javascript:" class="btn btn-primary btn-lg rounded-6" rel="nofollow" @click.stop="openSubmitProductModal"
			        ><i class="iconfont icon-tougao mr-2"></i>
			  	  <span v-if="isIos == 'Android'">设计师导航</span>
			  	  <span v-else>花猫导航</span>
			  	  </a
			      >
			    </div>
			  </li>
            <li class="d-md-none">
              <a id="sidebar-switch" data-toggle="modal" data-target="#sidebar"><i class="iconfont icon-classification icon-lg"></i></a>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
    <InquiryGuidance :inquiryGuidance="dialogVisible" @destruction="destruction"></InquiryGuidance>
  </div>
</template>

<script>
import { gethead } from '@/api/http'
import { API_URL } from '@/utils/request.js'
import InquiryGuidance from './InquiryGuidance.vue'
export default {
  components: { InquiryGuidance },
  data() {
    return {
      navigationList: [],
      APIUM: API_URL,
      siteName: localStorage.getItem('siteName'),
      dialogVisible: false,
	  isIos:''
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.collapse
    }
  },
  created() {
	  this.isIos = localStorage.getItem('keyTitle')
    this.checkMobile()
  },
  methods: {
    async checkMobile() {
      // 导航列表
      var query1 = {
        type: 0
      }
      const data1 = await gethead(query1)
      this.navigationList = data1.data.data
    },
    drawerShow() {
      this.$store.commit('toggleDrawer', true)
    },
    toggleMenu() {
      const val = this.$store.state.collapse
      this.$store.commit('collapse', !val)
    },
    clickJump(val) {
      this.$router.push(val)
    },
    clickJump2(val) {
      window.open(val, '_blank')
    },
    openSubmitProductModal() {
	  if(localStorage.getItem('keyTitle') === 'Ios'){
		  let keyTitle = 'Android'
		  if (localStorage.getItem('keyTitle')) {
		    keyTitle = localStorage.getItem('keyTitle') == 'Android' ? 'Ios' : 'Android'
		  }
		  this.dialogVisible = false
		  localStorage.setItem('keyTitle', keyTitle)
		  this.$emit('destruction')
		  window.location.reload()
	  }else{
		  this.dialogVisible = true
	  }
      
    },
    destruction() {
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
@import url('../../css/bootstrap.css');
@import url('../../css/style.css');
.siteName {
  padding-left: 15px;
}
.sidebar-logo {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  left: 20px;
  z-index: 100;
}
.menu-icon {
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 16px;
  position: relative;
  top: 2px;
}

.barDiv {
  background-color: #888;
  position: absolute;
  left: 0;
  transition: width 0.3s ease-in-out;
  height: 2px;
  width: 100%;
  border-radius: 1.5px;
}

.top {
  top: 0;
}

.middle {
  top: 50%;
  transform: translateY(-50%);
  transition: none; /* No transition for the middle bar */
}

.bottom {
  bottom: -1px;
}

.barDiv.active {
  width: 60%; /* Change width to 50% when active */
}
</style>
<style>
.el-dialog {
  border: rebeccapurple 1px solid;
  border-radius: 15px;
  overflow: hidden;
  .el-dialog__header {
    display: none;
  }
}
</style>
